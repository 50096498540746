<template>
  <div id="epg">
    <b-row class="mb-2">
      <b-col
        md="10"
        class="pb-5 pb-md-0"
      >
        <h1>{{ $t('progEpg') }}</h1>
        <h5 class="text-primary">
          {{ $t('confgEpg') }}
        </h5>
      </b-col>
      <b-col style="text-align-last: end;">
        <router-link to="/contents/epg">
          <b-button variant="danger">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showEpg"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-body class="m-2">
              <b-row>
                <b-col
                  md="4"
                  class="embed-responsive embed-responsive-16by9"
                >
                  <router-link
                    :to="{
                      name: 'contents-edit',
                      params: { id: idEpg }
                    }"
                  >
                    <b-img
                      :src="buildImageUrl(imageEpg)"
                      class="embed-responsive-item ml-1"
                      block
                      rounded
                    />
                  </router-link>
                </b-col>
                <b-col>
                  <b-card-header
                    class="d-flex"
                    style="flex-direction:column;align-items: flex-start;"
                  >
                    <div
                      class="d-flex"
                      style="align-items: center;"
                    >
                      <router-link
                        :to="{
                          name: 'contents-edit',
                          params: { id: idEpg }
                        }"
                      >
                        <h3>EPG {{ nameEpg }}</h3>
                      </router-link>
                    </div>
                    <div class="m-1 acortar_parrafo">
                      {{ descriptionEpg }}
                    </div>
                    <div
                      class="d-flex"
                      style="align-items:center;"
                    >
                      <b-button
                        variant="primary"
                        class="mt-1"
                        @click="$refs['my-modal'].show()"
                      >
                        {{ $t('dataGeneric.edit') }}
                      </b-button>
                    </div>
                  </b-card-header>
                </b-col>
                <b-col
                  class="d-flex"
                  style="flex-wrap: nowrap;
                  justify-content: flex-end;
                  align-content: center;
                  align-items: flex-start;"
                >
                  <b-badge
                    :class="isActive ? 'badge-light-success' : 'badge-light-danger'
                    "
                  >
                    <h3 :class="isActive ? 'text-success' : 'text-danger'">
                      {{ isActive ? $t('dataGeneric.active') : "Inactivo" }}
                    </h3>
                  </b-badge>
                  <b-form-checkbox
                    v-model="isActive"
                    class="ml-1 mt-1"
                    switch
                    @change="editEpg()"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showEpgCont"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <h3>
                {{ $t('prog') }}: <b>{{ formatDate(time, "F") }}</b>
              </h3>

              <div class="align-items-center d-flex">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="insertarFecha('tod')"
                >
                  {{ $t('today') }}
                </b-button>

                <b-button
                  variant="primary"
                  @click="insertarFecha('tom')"
                >
                  {{ $t('tomorrow') }}
                </b-button>

                <div class="mr-3">
                  <feather-icon
                    class="text-white cursor-pointer ml-2"
                    icon="CalendarIcon"
                    size="26"
                    @click="clickCalendar()"
                  />
                  <flat-pickr
                    id="time"
                    v-model="time"
                    style=" visibility: hidden;
                    width: 0;
                    height: 0;"
                    :config="{
                      dateFormat: 'Y-m-d',
                      defaultDate: [time]
                    }"
                  />
                </div>
                <router-link
                  :to="{
                    name: 'contents-epg-wizard',
                    params: { epg: code, time: time }
                  }"
                >
                  <b-button
                    variant="success"
                    class="cursor-pointer"
                  >
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="18"
                      class="mr-50"
                    />{{ $t('Add') }}
                  </b-button>
                </router-link>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-table
                    ref="table"
                    show-empty
                    responsive
                    sticky-header="500px"
                    :no-border-collapse="true"
                    striped
                    :items="epgChannel"
                    :fields="columns"
                  >
                    <template #cell(hora)="data">
                      <div class="text-nowrap">
                        <b-row>
                          <b-col>
                            <div
                              class="text-center"
                              style="align-self:center;"
                            >
                              <h3>
                                {{ formatDate(data.item.node.startDate, "H") }}
                              </h3>
                              <h6 style="color: gray">
                                {{ data.item.node.duration }} min
                              </h6>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template #cell(name)="data">
                      <b-row>
                        <b-col
                          cols="3"
                          class="embed-responsive embed-responsive-16by9"
                          style="width:400px"
                        >
                          <!--
                            tableImage
                            -->
                          <b-img
                            :src="buildImageUrl(data.item.node.image)"
                            class="embed-responsive-item"
                            @error="errorImg"
                          />
                        </b-col>
                        <b-col
                          id="acortarPadre"
                          cols="9"
                        >
                          <h5 id="acortar">
                            {{ data.item.node.name }}
                          </h5>
                          <div class="wrap">
                            <span class="acortar_parrafo">
                              {{ data.item.node.description }}
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                    <template #cell(content)="data">
                      <div class="d-flex">
                        <router-link
                          v-if="data.item.node.content != null"
                          :to="{
                            name: 'contents-edit',
                            params: { id: data.item.node.content.id }
                          }"
                        >
                          <feather-icon
                            icon="EyeIcon"
                            size="22"
                            class="text-success mr-50  cursor-pointer"
                          />
                        </router-link>

                        <b-form-checkbox
                          switch
                          class="ml-1"
                          :checked="data.item.node.content != null ? true : false"
                          @change="data.item.node.content != null ? link(data.item.node.id, 'unlink') : link(data.item.node.id, 'link')"
                        />
                      </div>
                    </template>
                    <template #cell(action)="data">
                      <div class="text-nowrap text-center">
                        <router-link
                          :to="{
                            name: 'contents-epg-edit',
                            params: { epg: data.item.node.id }
                          }"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="22"
                            class="text-warning cursor-pointer"
                          />
                        </router-link>

                        <feather-icon
                          icon="XCircleIcon"
                          size="22"
                          class="text-danger ml-50 cursor-pointer"
                          @click="deleteEpgContent(data.item.node.id)"
                        />
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('contents.selectContent')"
    >
      <div class="d-block text-center">
        <content-selector
          v-if="linkId == null"
          :type-content="'DIR'"
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
        <content-selector
          v-else
          @data="contentLinkId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['my-modal'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
  BCard,
  BImg,
  BCardHeader,
  BCardBody,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, utf8ToB64, showToast } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'
import ContentSelector from '@/views/common/ContentSelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    ContentSelector,
    BCardHeader,
    BTable,
    flatPickr,
    BCardBody,
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BCard,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showEpg: false,
      showEpgCont: false,
      linkId: null,
      code: this.$route.params.id,
      vacio: true,
      nameEpg: '',
      idEpg: null,
      time: null,
      descriptionEpg: '',
      isActive: false,
      imageEpg: '',
      epgChannel: [],
      contentName: null,
      allGenre: [],
      allClasification: [],
      startDate: null,
      descriptionContent: null,
      userData: getUserData(),
      columns: [
        { key: 'hora', label: this.$t('Hora') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'content', label: this.$t('epg.linkedContent') },
        { key: 'action', label: this.$t('resources.tableHeader.actions') },
      ],
      nameContent: null,
      imageContent: null,
      timeContent: null,
      durationContent: null,
      clasificationContent: null,
      genreContent: null,
      vinculoContentName: null,
      vinculoContentId: null,
    }
  },
  watch: {

    time() {
      this.filterByDate()
    },
  },
  mounted() {
    const tiempoTranscurrido = Date.now()
    const hoy = new Date(tiempoTranscurrido)
    try {
      const [time] = hoy.toISOString().split('T')
      this.time = time
    } catch (error) {
      this.time = null
    }
    this.getData()
  },
  methods: {
    updateDataEPGContent() {
      this.showEpgCont = true

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation ($description: String){
           updateEpgContent(id:"${this.idEpg}",input:{name:"${this.nameContent}",
          description: $description,
        
          image:"${this.imageContent}"}){
            epgContent{
              name
            }
          }
        }
        `
      const variables = {
        description: this.descriptionEpg,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.showEpgCont = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    updateTimeEPGContent() {
      this.showEpgCont = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation ($description: String,$startDate: DateTime){
           updateEpgContent(id:"${this.idEpg}",input:{
          startDate: $startDate,
          duration:${this.durationContent}}){
            epgContent{
              name
            }
          }
        }
        `
      const variables = {
        startDate: this.timeContent,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.showEpgCont = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    updateInfoEPGContent() {
      this.showEpgCont = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation ($description: String,$startDate: DateTime){
           updateEpgContent(id:"${this.idEpg}",input:{
          clasification:"${this.clasificationContent}",
          genre:"${this.genreContent}",
          content:"${this.vinculoContentId}",
         }){
            epgContent{
              name
            }
          }
        }
        `

      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.showEpgCont = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    editEpg() {
      this.showEpg = true
      axios
        .post('', {
          query: `
        mutation{
            updateEpg(id:"${this.code}",input:{content:"${this.idEpg}",isActive:${this.isActive}}){
              epg {
                    id
                    isActive
                    content {
                        id
                        name
                        description
                        imageUrl
                    }
                    }
                }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.nameEpg = result.data.data.updateEpg.epg.content.name
          this.idEpg = result.data.data.updateEpg.epg.content.id
          this.isActive = result.data.data.updateEpg.epg.isActive
          this.imageEpg = result.data.data.updateEpg.epg.content.imageUrl
          this.descriptionEpg = result.data.data.updateEpg.epg.content.description

          showToast(this.$t('success'), 1, this)
          this.showEpg = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    formatDate(value, t = 'F') {
      const hoy = new Date(value)
      if (t === 'F') return hoy.toLocaleDateString()
      return hoy.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    filterByDate() {
      this.showEpgCont = true
      axios
        .post('', {
          query: `
            {
        allEpgContent(epg:"${utf8ToB64(
    `epg:${this.code}`,
  )}",startDate:"${this.time}",orderBy: "startDate",) {
            edges {
            node {
                 id
              startDate
              name
              image
              description
              duration
              clasification {
                id
                initial
              }
              genre {
                id
                name
              }
              content {
                id
                name
                imageUrl
              }
            }
            }
        }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.epgChannel = result.data.data.allEpgContent.edges
          this.showEpgCont = false
        })
        .catch(() => { })
    },
    getData() {
      this.showEpg = true

      axios
        .post('', {
          query: `
            {
                allEpg(id:"${this.code}",client:"${this.userData.profile.client.id}") {
                edges {
                    node {
                    id
                    isActive
                    content {
                        id
                        name
                        description
                        imageUrl
                    }
                    }
                }
            }

        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.nameEpg = result.data.data.allEpg.edges[0].node.content.name
          this.idEpg = result.data.data.allEpg.edges[0].node.content.id
          this.isActive = result.data.data.allEpg.edges[0].node.isActive
          this.imageEpg = result.data.data.allEpg.edges[0].node.content.imageUrl
          this.descriptionEpg = result.data.data.allEpg.edges[0].node.content.description
          this.showEpg = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteEpgContent(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios
              .post('', {
                query: `
                  mutation{
                        deleteEpgContent(id:"${id}") {
                            found
                            deletedId
                        }
                    }
                `,
              })
              .then(r => {
                messageError(r, this)

                showToast(this.$t('success'), 1, this)
                this.filterByDate()
              })
              .catch(() => {
                showToast(this.$t('error'), 0, this)
              })
          }
        })
        .catch(() => { })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    clickCalendar() {
      document.getElementById('time').click()
    },
    link(id, type) {
      this.linkId = id
      if (type === 'link') {
        this.$refs['my-modal'].show()
      } else {
        axios
          .post('', {
            query: `
        mutation{
            updateEpgContent(id:"${id}",input:{content:""}){
              epgContent {
                    id
                    isActive
                    content {
                        id
                        name
                        description
                        imageUrl
                    }
                    }
                }
            }
        `,
          })
          .then(result => {
            messageError(result, this)

            this.filterByDate()
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    contentLinkId(data) {
      axios
        .post('', {
          query: `
        mutation{
            updateEpgContent(id:"${this.linkId}",input:{content:"${data}"}){
              epgContent {
                    id
                    isActive
                    content {
                        id
                        name
                        description
                        imageUrl
                    }
                    }
                }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.linkId = null
          this.$refs['my-modal'].hide()
          this.filterByDate()
        })
        .catch(err => {
          console.log(err)
          this.$refs['my-modal'].hide()
        })
    },

    SelectContentId(data) {
      this.idEpg = data
      this.editEpg()
      this.$refs['my-modal'].hide()
    },
    SelectContentUrl(data) {
      this.contentName = data
      this.$refs['my-modal'].hide()
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
    insertarFecha(dia) {
      if (dia === 'tod') {
        const tiempoTranscurrido = Date.now()
        const hoy = new Date(tiempoTranscurrido)
        try {
          const [timeToday] = hoy.toISOString().split('T')
          this.time = timeToday
        } catch (error) {
          this.time = null
        }
      } else {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        const [timeTom] = tomorrow.toISOString().split('T')

        this.time = timeTom
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#epg .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#epg .wrap {
  white-space: pre-wrap;
}

#epg .card-body h4 {
  font-size: 1.286rem !important;
}

#epg .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#epg .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#epg .card img {
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}

#epg .card-body {
  padding: 0rem;
}

#epg .card-body h4 {
  font-size: 1rem !important;
}

#epg .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.tableImage {
  border-radius: 10px;
  max-width: 100%;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.acortar_parrafo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
